import { AnimatePresence, motion } from 'framer-motion';
import Portal from './Portal';
import Toast from './Toast';

type SnackbarProps = {
  type?: 'danger' | 'warning' | 'success' | 'info';
  onDismiss: () => void;
  [key: string]: any;
};

const Snackbar = ({ type = 'info', onDismiss, ...props }: SnackbarProps) => (
  <Portal id="snackbars">
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0 }}
        className="mt-1 pointer-events-auto"
      >
        <Toast {...props} onDismiss={onDismiss} type={type === 'danger' ? 'error' : type} />
      </motion.div>
    </AnimatePresence>
  </Portal>
);

export default Snackbar;
