import { ElementType, ReactNode } from 'react';

import cx from '@/lib/cx';

type FormTitleProps<C extends ElementType = 'h2'> = PolymorphicComponentProp<C>;

type FormTitleComponent = <C extends ElementType = 'h2'>(props: FormTitleProps<C>) => ReactNode;

const FormTitle: FormTitleComponent = ({ as, className, ...props }) => {
  const Component = as || 'h2';
  return (
    <Component className={cx('mb-6 text-xl font-medium leading-tight', className)} {...props} />
  );
};

export default FormTitle;
