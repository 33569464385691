import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';

import config from 'config';
import { useAuth } from 'context/Auth';
import useScreen from 'hooks/useScreen';
import AccountSettings from 'components/account/AccountSettings';
import Link from 'components/common/Link';
import Avatar from 'components/common/Avatar';
import UserMenu from './UserMenu';

const AuthMenu = () => {
  const { user } = useAuth();
  const [showSettings, setShowSettings] = useState(false);
  const screen = useScreen();
  if (showSettings) {
    return <AccountSettings onHide={() => setShowSettings(false)} />;
  }

  if (!user) {
    return (
      <Link
        href="/login"
        className="font-medium text-gray-800 hover:text-gray-600 flex items-center transition-colors duration-200"
      >
        <span className="inline-block">Sign In</span>
      </Link>
    );
  }

  return (
    <UserMenu
      className="min-w-sm -my-2"
      showSettings={() => setShowSettings(true)}
      trigger={
        !screen.lg ? (
          <FontAwesomeIcon icon={faUserCircle} size="lg" className="mr-2" />
        ) : (
          <Avatar
            image={user.avatarImage ?? config('/defaultFundraiserAvatar')}
            className="hover:bg-gray-400 transition-colors duration-200"
          />
        )
      }
    />
  );
};

export default AuthMenu;
