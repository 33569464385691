import { PropsWithChildren, useState } from 'react';
import { faTimes, faHomeAlt, faUserCog, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import config from 'config';
import { useAuth } from 'context/Auth';
import { formatFullName } from 'lib/formatters';
import AccountSettings from 'components/account/AccountSettings';
import ClickStop from 'components/common/ClickStop';
import Link from 'components/common/Link';
import Avatar from 'components/common/Avatar';
import PopMenu from 'components/common/PopMenu';
import AppBarToolbar from 'components/common/AppBarToolbar';
import AppBarIcon from 'components/common/AppBarIcon';
import Tiles from 'components/common/Tiles';
import LogoKey from '../../svg/logo-key.svg';

type UserMenuProps = PropsWithChildren<{
  trigger: NonEmptyReactNode;
  className?: string;
}>;

const UserMenu = ({ className = '', children, trigger }: UserMenuProps) => {
  const { user, logout } = useAuth();
  const [showSettings, setShowSettings] = useState(false);

  if (showSettings) {
    return <AccountSettings onHide={() => setShowSettings(false)} />;
  }

  if (!user) return null;
  return (
    <PopMenu trigger={trigger} closeOnClick>
      <div className={cx('py-6 flex flex-col justify-between w-96', className)}>
        <div className="sm:hidden -mt-3 -mx-5 mb-4 px-6">
          <AppBarToolbar>
            <Tiles spacing="sm">
              <button type="button">
                <AppBarIcon icon={faTimes} />
              </button>
            </Tiles>
            <LogoKey className="h-6 block fill-gray-900" />
            <Tiles />
          </AppBarToolbar>
        </div>
        <div className="text-left flex justify-start items-center mb-4 px-6 truncate">
          <Avatar
            image={user.avatarImage ?? config('/defaultFundraiserAvatar')}
            size="lg"
            className="shrink-0 mr-4"
          />
          <div>
            <p className="text-xl font-medium">{formatFullName(user.firstName, user.lastName)}</p>
            <p className="text-sm text-gray-600">{user.email}</p>
          </div>
        </div>
        <Link
          href="/account"
          className="font-medium text-gray-700 py-4 flex justify-start items-center hover:bg-gray-100 px-6"
        >
          <FontAwesomeIcon icon={faHomeAlt} size="lg" className="mr-6" />
          Account home
        </Link>
        <ClickStop>
          <button
            type="button"
            onClick={() => setShowSettings(true)}
            className="font-medium text-gray-700 py-4 w-full flex justify-start items-center hover:bg-gray-100 px-6"
          >
            <FontAwesomeIcon icon={faUserCog} size="lg" className="mr-6" />
            Manage account
          </button>
        </ClickStop>
        <button
          type="button"
          onClick={logout}
          className="font-medium text-gray-700 py-4 flex justify-start items-center hover:bg-gray-100 px-6"
        >
          <FontAwesomeIcon icon={faSignOut} size="lg" className="mr-6" />
          Sign out
        </button>
        {children && <div className="mt-12">{children}</div>}
      </div>
    </PopMenu>
  );
};

export default UserMenu;
